import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import  { useIdentityContext } from "react-netlify-identity-widget"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const isLogged = useIdentityContext().isLoggedIn;
  if (!isLogged && location.pathname !== `/app/login`) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/app/login`)
    return null
  }
  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PrivateRoute