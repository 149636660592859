import React from "react"
// import { navigate } from '@reach/router';
import IdentityModal, { useIdentityContext } from "react-netlify-identity-widget"
import "react-netlify-identity-widget/styles.css" // delete if you want to bring your own CSS


const Login = () => {
  // if (IsLoggedIn()) {
  //   navigate(`/app/profile`)
  // }
  const identity = useIdentityContext()
  const [dialog, setDialog] = React.useState(false)
  const name =
    (identity && identity.user && identity.user.user_metadata && identity.user.user_metadata.full_name) || "NoName"
  const isLogged = identity && identity.isLoggedIn

  return (
    <>
        <p>Please sign-in to access to the private route:</p>
        <button className="btn" onClick={() => setDialog(true)}>
            {isLogged ? `Hello ${name}, Log out here!` : "LOG IN"}
        </button>
        <IdentityModal showDialog={dialog} onCloseDialog={() => setDialog(false)} />
    </>
  );

}

export default Login