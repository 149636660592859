import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import Profile from "../components/Profile"
import Details from "../components/Details"
import Login from "../components/Login"
import PrivateRoute from "../components/PrivateRoute"
import NotFound from "../components/NotFound"


const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/details" component={Details} />
      <PrivateRoute path="/app/profile" component={Profile} />
      <Login path="/app" />
      <Login path="/app/login"/>
      <NotFound default/>
    </Router>
  </Layout>
)

export default App