import React from "react"


const Details = () => {
  // useEffect(() => {
  //   // get data from GitHub api
  //   fetch(`http://128.199.205.42:1337/customers`)
  //     .then(response => response.json()) // parse JSON from request
  //     .then(resultData => {
  //       console.log(resultData)
  //     }) // set data for the number of stars
  // }, [])
  return (
    <>
      <p>
        This is a client-only route. You can get additional information about a
        user on the client from this page.
      </p>
      <ul>
        <li>Preferred name: dfsdf</li>
        <li>Legal name: dfas</li>
        <li>Email address: fdsafd</li>
      </ul>
    </>
  )
}

export default Details
