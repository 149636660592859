import React from "react"
import  { useIdentityContext } from "react-netlify-identity-widget"


const Profile = () => {
  const user = useIdentityContext().user;
  return (
    <>
      <p>Welcome back to your profile, {user.user_metadata.full_name}! - Email: {(user.email)}</p>
      <p>Token: {JSON.stringify(user.token)}!</p>
      <p>
        This is a client-only route. You could set up a form to save information
        about a user here.
      </p>
    </>
  )
}

export default Profile